'use client'

import React from 'react'
import Link from 'next/link'
import { setCookieConsent } from '@/server/actions'
import { ArrowTopRightIcon } from '@radix-ui/react-icons'

import { Button } from './ui/button'

type Props = {}

export const CookieBar = (props: Props) => {
  return (
    <section className='bg-theme-accent container sticky bottom-0 my-4 flex flex-row items-center justify-between gap-4 py-4 text-sm text-white xl:rounded'>
      <p>
        Folosim cookie-uri pentru a ne asigura că vă oferim cea mai bună
        experiență pe site-ul nostru.{' '}
        <span className='hidden sm:inline'>
          Dacă continuați să utilizați acest site, vom presupune că sunteți de
          acord cu termenii noștri de confidențialitate.
        </span>
      </p>

      <form className='flex flex-col items-center gap-4 sm:flex-row'>
        <Link
          href='/termeni'
          className='flex items-center whitespace-nowrap hover:underline'>
          Află mai multe
          <ArrowTopRightIcon className='mx-2 h-4 w-4' />
        </Link>
        <Button
          variant='default'
          className='w-full bg-theme-muted md:w-auto'
          formAction={setCookieConsent}>
          Ok
        </Button>
      </form>
    </section>
  )
}
