import(/* webpackMode: "eager" */ "/vercel/path0/components/cookie-bar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/email-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/newsletter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/recommendations-slider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/toaster.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"assets/local-fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/kingsbridge_ex_el.ttf\",\"weight\":\"150\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/kingsbridge_ex_el_it.ttf\",\"weight\":\"150\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/kingsbridge_lt.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/kingsbridge_lt_it.ttf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/kingsbridge_bk.ttf\",\"weight\":\"350\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/kingsbridge_bk_it.ttf\",\"weight\":\"350\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/kingsbridge_rg.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/kingsbridge_rg_it.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../assets/fonts/kingsbridge_bd.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../assets/fonts/kingsbridge_bd.ttf\",\"weight\":\"600\",\"style\":\"italic\"}],\"variable\":\"--font-website\"}],\"variableName\":\"localFonts\"}")