'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { type subscribers } from '@prisma/client'
import { PaperPlaneIcon } from '@radix-ui/react-icons'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { subscribeToNewsletter } from '@/server/actions'

const FormSchema = z.object({
  name: z.string().min(2, 'Numele este prea scurt'),
  email: z.string().min(4, 'Email incorect')
})

export const Newsletter = () => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: '',
      email: ''
    }
  })

  function onSubmit(data: z.infer<typeof FormSchema>) {
    subscribeToNewsletter(data as subscribers)
    form.reset()
  }

  return (
    <div className='bg-theme-accent'>
      <section className='container my-4 flex flex-col items-center justify-between gap-4 py-8 text-sm text-white sm:flex-row sm:items-end md:mb-12'>
        <div className='flex w-full flex-col gap-4 md:w-auto lg:grow lg:flex-row'>
          <p className='text-bold flex items-center text-xl'>Newsletter</p>
          <p className='md:mx-auto'>
            Interesat de dezvoltare personală? <br />
            Abonează-te și primești tot ce voi publica.
          </p>
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className='flex w-full items-end justify-center gap-4 md:w-auto'>
            <div className='flex grow flex-col gap-4 lg:flex-row'>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => (
                  <FormItem>
                    <FormControl className='relative'>
                      <Input
                        {...field}
                        autoComplete='off'
                        placeholder='Nume'
                        variant='floating'
                        className='placeholder:text-white'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='email'
                render={({ field }) => (
                  <FormItem>
                    <FormControl className='relative'>
                      <Input
                        {...field}
                        type='email'
                        autoComplete='off'
                        placeholder='Email'
                        variant='floating'
                        className='placeholder:text-white'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button
              type='submit'
              variant='secondary'
              className='whitespace-nowrap text-theme-muted'
              disabled={!form.formState.isDirty}>
              <PaperPlaneIcon className='h-4 w-4' />
            </Button>
          </form>
        </Form>
      </section>
    </div>
  )
}
