'use client'

import { Fragment, useState } from 'react'
import { type recommendations } from '@prisma/client'
import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons'

import { cn } from '@/lib/utils'

import { Button } from './ui/button'

type Props = {
  recommendations: recommendations[]
}

export const RecommendationsSlider = ({ recommendations }: Props) => {
  const [slide, setSlide] = useState(0)
  const featureEnabled = recommendations?.length > 0
  const activeSlide = (index: number) =>
    cn({
      flex: true,
      hidden: slide !== index
    })
  const previousSlide = () =>
    setSlide(slide === 0 ? recommendations?.length - 1 : slide - 1)
  const nextSlide = () =>
    setSlide(slide === recommendations?.length - 1 ? 0 : slide + 1)

  return (
    <>
      {featureEnabled && (
        <div className='flex flex-col items-center bg-theme-background'>
          <section className='flex items-center justify-center gap-12 pt-8'>
            <div className='h-6 w-6'>
              <Button
                asChild
                variant='link'
                className='cursor-pointer p-0 text-black/50 hover:text-black'
                onClick={previousSlide}>
                <ArrowLeftIcon className='h-6 w-6' />
              </Button>
            </div>

            <div className='h-6 w-6'>
              <Button
                asChild
                variant='link'
                className='cursor-pointer p-0 text-black/50 hover:text-black'
                onClick={nextSlide}>
                <ArrowRightIcon className='h-6 w-6' />
              </Button>
            </div>
          </section>
          {recommendations?.map(({ id, name, content }, index) => (
            <div key={id} className={activeSlide(index)}>
              <section className='container flex justify-center pb-8 pt-6'>
                <p className='relative w-8/12 font-light italic text-theme first-letter:text-3xl before:absolute before:-left-10 before:top-8 before:text-6xl before:content-["\""] sm:w-4/6 sm:first-letter:text-7xl sm:before:-left-16 sm:before:top-10 sm:before:text-9xl lg:w-3/6'>
                  {content}
                  <span className='regular my-4 block font-bold'>{name}</span>
                </p>
              </section>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
