'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { AdminLink } from '@/components/admin-link'

import { websiteLinks } from '@/config/website-links'

import { cn } from '@/lib/utils'
import { Icons } from '@/assets/icons'

type Props = {
  isLive: boolean
}

export const Navigation = ({ isLive }: Props) => {
  const pathname = usePathname()

  return (
    <>
      <div className='flex h-28 items-center justify-between gap-4 font-serif text-xl md:hidden'>
        <AdminLink isLive={isLive} />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='link' size='icon' className='text-theme'>
              <Icons.menu />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-56 text-theme'>
            <DropdownMenuLabel>Meniu</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {websiteLinks.map(({ title, path, icon: Icon }) => (
              <Link key={title} href={path} className='flex items-center gap-2'>
                <DropdownMenuItem className='flex w-full justify-between'>
                  {title}
                </DropdownMenuItem>
              </Link>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className='hidden h-28 items-center justify-between gap-4 md:flex'>
        <AdminLink isLive={isLive} />
        {websiteLinks.map(({ title, path }) => {
          const isActive = pathname?.startsWith(path)
          return (
            <Link
              key={title}
              href={path}
              className={cn(
                'flex items-center gap-2 whitespace-nowrap text-[#555555] transition-colors duration-150 hover:text-theme',
                isActive ? 'text-[#8B8386]' : 'text-[#555555]'
              )}>
              {title}
            </Link>
          )
        })}
      </div>
    </>
  )
}
