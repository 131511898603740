'use client'

import { ClipboardCopyIcon, CopyIcon } from '@radix-ui/react-icons'
import { useCopyToClipboard } from 'usehooks-ts'

import { cn } from '@/lib/utils'

import { Button } from './ui/button'

type Props = {
  emailAddress?: string
}

export const EmailButton = ({ emailAddress }: Props) => {
  const [value, copy] = useCopyToClipboard()
  const [username, domain] = emailAddress?.split('@') || ['']

  return (
    <div className='inline-flex cursor-pointer items-center gap-2'>
      {/* <div className='flex items-center gap-2'>
        <div
          onClick={() => copy('florentina.hariton@yahoo.com')}
          className='w-full p-0 text-current before:content-["florentina.hariton"] after:content-["yahoo.com"]'>
          @
        </div>
        <div className='flex w-6 items-center'>
          {value ? (
            <ClipboardCopyIcon className='h-4 w-4 text-green-600' />
          ) : (
            <Button
              variant='link'
              onClick={() => copy('florentina.hariton@yahoo.com')}
              className='p-0 text-current'>
              <CopyIcon className='h-4 w-4' />
            </Button>
          )}
        </div>
      </div> */}

      <div
        onClick={() => copy(emailAddress || '')}
        className={cn(
          'w-full p-0 text-current',
          `before:content-["${username}"]`,
          `after:content-["${domain}"]`
        )}>
        @
      </div>
      <div className='flex w-6 items-center'>
        {value ? (
          <ClipboardCopyIcon className='text-current/30 h-4 w-4' />
        ) : (
          <CopyIcon className='h-4 w-4' />
        )}
      </div>
    </div>
  )
}
